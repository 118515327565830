import { TextOnImageTeaser as ChameleonTextOnImageTeaser } from '@mediahuis/chameleon-react';
import { type FC, type ReactElement, type RefObject, useRef } from 'react';

import { getThemeDataAttributes } from '@hubcms/utils-theme';

import { NativeTeaserImage } from '../NativeTeaserImage';
import { TeaserLink } from '../TeaserLink';
import { TeaserVideoPreview } from '../TeaserVideoPreview';

import { getChameleonProps } from './getChameleonProps';
import type { TextOnImageTeaserProps } from './types';

const getTeaserImage = ({
  videoPreviewId,
  teaserRef,
  ...props
}: TextOnImageTeaserProps & {
  videoPreviewId?: string;
  teaserRef?: RefObject<HTMLElement>;
}): ReactElement => {
  return (
    <>
      {props.image && (
        <NativeTeaserImage
          image={props.image}
          sizes={props.imageSizes}
          format={props.imageFormat}
          isPriority={props.isImagePriority}
        />
      )}
      {videoPreviewId && teaserRef && <TeaserVideoPreview previewId={videoPreviewId} teaserRef={teaserRef} />}
    </>
  );
};

const getTeaserLink = (props: TextOnImageTeaserProps): React.ReactNode => {
  if (typeof props.link === 'string' && props.trackingData) {
    return <TeaserLink url={props.link} isExternal={props.isExternal} trackingData={props.trackingData} />;
  }
  return props.link;
};

const TextOnImageTeaser: FC<TextOnImageTeaserProps> = props => {
  const teaserRef = useRef<HTMLDivElement>(null);
  const teaserImage = getTeaserImage({ ...props, videoPreviewId: props.videoPreviewId, teaserRef });
  const link = getTeaserLink(props);
  const chameleonProps = getChameleonProps(props, teaserImage);
  const themeDataAttributes = props.theme ? getThemeDataAttributes(props.theme) : null;

  return (
    <ChameleonTextOnImageTeaser
      ref={teaserRef}
      {...chameleonProps}
      link={link}
      data-article-id={props.id}
      data-list-id={props.list?.id}
      {...themeDataAttributes}
    />
  );
};

export default TextOnImageTeaser;
